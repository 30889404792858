@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,400italic,500italic,700italic&display=swap);


/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:root {
	--background: #f4f5f7;

	--modal-overlay: rgba(165, 165, 175, 0.25);

	--black: #252525;
	--white: rgb(254, 254, 254);
	--extra: rgba(165, 165, 175, 0.75);
	--extra-dark: rgba(105, 105, 105, 0.75);

	--main: #02a99c;
	--main-alpha: #02a99b7e;

	--green: rgba(0, 200, 0, 1);

	--font: 'Roboto';
	--fontAlt: 'Roboto';
}

::-webkit-scrollbar {
	width: 6px; /* for vertical scrollbars */
	height: 6px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track {
	background: #f4f5f7;
	background: var(--background);
}
::-webkit-scrollbar-thumb {
	background: rgba(165, 165, 175);
}

body,
html {
	height: 100%;
	width: 100%;
}
body {
	background-color: #f4f5f7;
	background-color: var(--background);
	font-size: 13px;
	color: #252525;
	color: var(--black);
	font-style: normal;
	font-weight: 400;
	line-height: 1.42857142857143;
	font-family: 'Roboto';
	font-family: var(--font);
}
em {
	font-style: italic;
}

b,
strong {
	font-weight: 700;
}

a {
	cursor: pointer;
	text-decoration: none;
	font-family: 'Roboto';
	font-family: var(--font);
}
a:hover {
	text-decoration: none;
	opacity: 0.5;
}

a:link {
	text-decoration: inherit;
	color: inherit;
	color: #02a99c;
	color: var(--main);
	cursor: pointer;
}

a:visited {
	text-decoration: inherit;
	color: inherit;
	cursor: auto;
}

a:active {
}
a:focus {
	outline: 0px solid;
	outline-offset: 0px;
}

p {
	line-height: auto;
	margin: 0;
	font-family: 'Roboto';
	font-family: var(--font);
}

input[type='file'] {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	/*alternative to pointer-events, compatible with all browsers, just make it impossible to find*/
	width: 1px;
	height: 1px;
	font-family: 'Roboto';
	font-family: var(--font);
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

button {
	cursor: pointer;
	font-size: 14px;
	font-family: 'Roboto';
	font-family: var(--fontAlt);
	line-height: 20px;
	letter-spacing: 0.4px;
	/* text-transform: uppercase; */
	font-weight: 400;
	border: 0;
	padding: 0;
	background: rgb(254, 254, 254);
	background: var(--white);
}
button:hover,
button:focus {
}
button:focus {
	outline: 0px solid rgb(254, 254, 254);
	outline: 0px solid var(--white);
	outline-offset: -4px;
}
button:active {
	/*transform: scale(0.99); makes images blurry...*/
	/* background: var(--main-alpha); */
	/*opacity:.9;*/
	/* color: var(--white) */
}

.react-confirm-alert-blur {
	-webkit-filter: url(#gaussian-blur);
	        filter: url(#gaussian-blur);
	filter: blur(2px);
	-webkit-filter: blur(2px);
}
.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(165, 165, 175, 0.25);
	background: var(--modal-overlay);
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
.react-confirm-alert-body {
	font-family: Arial, Helvetica, sans-serif;
	width: 400px;
	padding: 30px;
	text-align: left;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
	color: #666;
}
.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}
.react-confirm-alert-body > h1 {
	margin-top: 0;
}
.react-confirm-alert-body > h3 {
	margin: 0;
	font-size: 16px;
}
.react-confirm-alert-button-group {
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}
.react-confirm-alert-button-group > button {
	outline: none;
	background: #333;
	border: none;
	display: inline-block;
	padding: 6px 18px;
	color: #eee;
	margin-right: 10px;
	border-radius: 5px;
	font-size: 12px;
	cursor: pointer;
}
@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.wrapMargin {
	margin: -5px;
}
.wrapMargin > * {
	margin: 5px;
}

/* tooltip styles */
.react-tooltip-lite {
	min-width: 302px;
	min-height: 152px;
	opacity: 0.9;
	border-radius: 10px;
	background-color: rgba(245, 112, 39, 0.89);
	line-height: 1.64;
	color: '#ffffff';
	padding: 6px 10px;
}
.react-tooltip-lite a {
	color: #86b0f4;
	text-decoration: none;
}
.react-tooltip-lite a:hover {
	color: #4286f4;
}
.react-tooltip-lite-arrow {
	border-color: #252525;
	border-color: var(--black);
	opacity: 0.1;
	box-shadow: 0 20px 32px -8px rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.03),
		0 6px 20px 0 rgba(0, 0, 0, 0.03);
}
.react-tooltip-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
*:focus {
	outline: 0;
	outline: none;
}

.clamp-lines > span {
	display: block;
}

.police {
	position: sticky;
	top: 120px;
}

.container {
	display: flex;
	flex-direction: column;
}
.recharts-wrapper {
	margin-left: -2vw;
}

